<template>
	<ul class="payment-list">
		<li
			v-for="item in list"
			:key="item.id"
			class="payment-list-item"
		>
			<router-link
				:to="{
					name: 'PaymentSetting',
					params: { id: item.id }
				}"
				class="payment-list-link d-flex align-items-center justify-content-between"
			>
				{{ item.name }}
				<span class="payment-list-icon">
					<CIcon class="cil-pencil" name="cil-pencil" />
				</span>
			</router-link>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'PaymentMethodList',

	props: {
		list: {
			type: Array,
			default: () => [],
		},
	},
};
</script>

<style lang="scss">
	.payment-list {
		padding: 0;
		margin: rem(32) 0;
		border: 1px solid $color-gray-300;
		border-radius: 4px;
		list-style: none;

		// .payment-list-link
		&-item {
			border-bottom: 1px solid $color-gray-300;

			&:last-child {
				border-bottom: none;
			}
		}

		// .payment-list-link
		&-link {
			display: inline-block;
			font-size: rem(18);
			font-weight: 600;
			color: $color-black-90;
			height: rem(76);
			padding: rem(24) rem(10) rem(24) rem(24);

			&:hover {
				text-decoration: none;

				.payment-list-icon {
					color: $color-hypertext;
				}
			}
		}

		// .payment-list-icon
		&-icon {
			display: flex;
			width: rem(40);
			height: rem(40);
			align-items: center;
			justify-content: center;
			color: $color-gray-600;
		}
	}
</style>
