<template>
	<BaseLoading v-if="list.isLoading" is-full-page />
	<div v-else class="main-wrapper mt-4">
		<CRow>
			<CCol lg="12">
				<h4>
					Select payment method
				</h4>
				<PaymentMethodList :list="paymentMethodGroupList" />
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import PaymentMethodList from '@/components/PaymentMethodList.vue';

export default {
	name: 'Payments',

	components: {
		PaymentMethodList,
	},
	computed: {
		...mapState('payments', {
			list: 'list',
		}),
		...mapGetters({
			paymentMethodGroupList: 'payments/paymentMethodGroupList',
		}),
	},
	created() {
		this.getPaymentMethodGroups();
	},
	methods: {
		...mapActions({
			getPaymentMethodGroups: 'payments/getPaymentMethodGroups',
		}),
	},
};
</script>
